var refreshing;
if ('serviceWorker' in navigator) {
    navigator.serviceWorker
            .register('serviceworker.js')
            .then(function () {
                console.log("Serviceworker registered");
            });
    navigator.serviceWorker.addEventListener('controllerchange',
            function () {
                if (refreshing)
                    return;
                refreshing = true;
                window.location.reload();
            }
    );
}

moment.locale('de');
var offset = moment().utcOffset();

$(document).ready(function () {
    loadUser();
    loadChats();
});

let darkmodeState = localStorage.getItem('darkmode');
if (darkmodeState && darkmodeState == 'true') {
    $('body').addClass('dark');
    $('#darkModeState').prop('checked', true);
} else {
    $('#darkModeState').prop('checked', false);
}


$('#menuHome').click(function () {
    $('.chat').hide();
    $('.home').show();
});

$('#menuChats').click(function () {
    loadChats();
});

$('#menuCharacters').click(function () {
    loadCharacters();
});

$('#startNow').click(function () {
    loadCharacters();
});

$('#menuFavorites').click(function () {
    loadFavorites();
});

$('#menuCredits').click(function () {
    loadCredits();
});

$('#createCharacterButton').click(function() {
   createCharacter(); 
});

$('#deleteChat').click(function() {
   deleteChat($(this).data('chat-id')); 
});

$('#startCharacterCreatorButton').click(function() {
    clearCharacterCreator();
    $('#createCharacterButton').html('Charakter erstellen <i class="fa fa-plus-circle" style="margin-left:10px;"></i>')
    $('#createCharacterModalTitle').html('Charakter erstellen');
    $('#createCharacterModalText').html('Hier kannst du deinen eigenen Charakter ganz nach deinen Vorgaben erschaffen. Um ein gutes Ergebnis zu erhalten empfehlen wir dir vorher unbedingt in unsere Anleitung zu schauen.<br /><br /> Um einen Charakter zu erstellen benötigst du einen Mindspark.');
    $('#createCharacterSaveText').html('<b>Du kannst deinen Charakter durch einen Klick auf den Button jetzt erstellen.</b> <br /><br />Der Charakter wird anschließend überprüft und steht dir dann direkt zum Gespräch zur Verfügung.');       
});

$('#startCharacterCreatorButton').click(function() {
    $('#characterCreatorIntro').show();
    $('#characterCreatorStep1').hide();
    $('#characterCreatorStep2').hide();
    $('#characterCreatorStep3').hide();
    $('#characterCreatorStep4').hide();
    $('#characterCreatorStep5').hide();
});

$('#saveChatTitle').click(function () {
    var chatId = $(this).data('chat-id');
    var newTitle = $('#chatTitleInput').val();
    $('#chatTitle').data('chat-title', newTitle);
    $('#chatTitle').data('chat-id', chatId);
    setTitle(chatId, newTitle);
});

$('body').on('click', '.startChatFromCharacterCard', function (data) {
    var characterId = $(this).data('character-id');
    createChat(characterId);
    $('#characterCardModal').modal('hide');
});

$('body').on('click', '.openBuyModal', function (data) {
    showBuyModal();
});

$('#signOut').click(function () {
    localStorage.clear();
    location.reload();
});

$('#darkModeSwitch').click(function () {
    var state = $('#darkModeState').is(':checked');
    if (state) {
        $('body').addClass('dark');
        localStorage.setItem('darkmode', true);
    } else {
        $('body').removeClass('dark');
        localStorage.setItem('darkmode', false);
    }
});

$('body').on('click', '.favoriteMessageButton', function (data) {
    var messageId = $(this).data('message-id');
    var state = $(this).data('favorite-state');
    var targetState = (state) ? 0 : 1;
    $(this).data('favorite-state', targetState);
    if (targetState) {
        $(this).addClass('bg-primary');
    } else {
        $(this).removeClass('bg-primary');
    }
    setFavorite(messageId, targetState);
});

$('body').on('click', '#removeFavoriteMessage', function (data) {
    var id = $(this).data('message-id');
    removeFavorite(id);
});

$('body').on('click', '.deleteMessageButton', function (data) {
    var messageId = $(this).data('message-id');
    if (confirm("Die Antwort und Ihre zugehörige Anfrage werden entfernt. Für den Charakter ist es als ob die Nachrichten nie existiert hätten.") == true) {
        deleteMessage(messageId);
    }
});

$('body').on('click', '.hideFromAiButton', function (data) {
    var messageId = $(this).data('message-id');
    if (confirm("Die Antwort und Ihre zugehörige Anfrage werden vor dem Charakter verborgen. Für den Charakter ist es als ob die Nachrichten nicht existieren.") == true) {
        var state = $(this).data('hide-state');
        var targetState = (state) ? 0 : 1;
        $(this).data('hide-state', targetState);
        if (targetState) {
            $(this).addClass('bg-primary');
        } else {
            $(this).removeClass('bg-primary');
        }
        hideFromAiMessage(messageId, targetState);
    }
});


$('#createCharacterImage').on('change', function(event) {
    
    console.log('image selected');
  const file = event.target.files[0];
  
  const reader = new FileReader();
  reader.readAsDataURL(file);
  
  reader.onload = function() {
    const img = new Image();
    img.src = reader.result;
    
    img.onload = function() {
      const aspectRatio = img.width / img.height;
      const maxWidth = 500;
      let width = maxWidth;
      let height = maxWidth / aspectRatio;
      if (width > img.width && height > img.height) {
        width = img.width;
        height = img.height;
      }
      
      const canvas = $('<canvas>')[0];
      canvas.width = width;
      canvas.height = height;
      
      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, width, height);
      
      $('#createCharacterImagePlaceholder').attr('src', canvas.toDataURL());
      $('#createCharacterImage').data('image', canvas.toDataURL());
    };
  };
});


/**
 * Some examples of how to use features.
 *
 **/

var SohoExamle = {
    Message: {
        add: function (message, type, time, tokens, creationTime, messageId, favorite = false, hide = false, reloadChatsOnContextUpdate = false) {
            var chat_body = $('.layout .content .chat .chat-body');
            if (chat_body.length > 0) {

                type = type ? type : '';
                var messageRaw = message;
                message = message ? markdown(message) : '...';
                message = message.replace(/(?:\r\n|\r|\n)/g, "<br />");

                var messageTs = Date.parse(time);
                var messageTime = new Date(messageTs);
                time = moment.utc(messageTime).local().locale('de').format('LLL');
                var favoriteActive = (favorite) ? 'bg-primary' : '';
                var favoriteState = (favorite) ? 1 : 0;
                var hideActive = (hide) ? 'bg-primary' : '';
                var hideState = (hide) ? 1 : 0;
                $('.messagePendingIndicator').remove();
                if (type == 'assistant') {
                    $('#preChatInfo').hide();
                    $('.layout .content .chat .chat-body .messages').append('<div data-message-id="' + messageId + '" class="message-item incoming-message"><div class="message-content">' + message + '<hr/><a href="#" data-message-id="' + messageId + '" data-favorite-state="' + favoriteState + '" class="favoriteMessageButton ' + favoriteActive + ' btn btn-outline-light btn-sm"><i class="ti-star"></i></a> <a style="display:none;" href="#" data-message-id="' + messageId + '" class="shareMessageButton btn btn-outline-light btn-sm"><i class="ti-share"></i></a> <a href="#" data-hide-state="' + hideState + '" data-message-id="' + messageId + '" class="hideFromAiButton ' + hideActive + ' btn btn-outline-light btn-sm"><i class="fa fa-eye-slash"></i></a> <a href="#" data-message-id="' + messageId + '" class="deleteMessageButton btn btn-outline-light btn-sm"><i class="ti-trash"></i></a></div><div class="message-action">' + time + ' | ' + tokens + ' MindBits  | Zeit: ' + creationTime + 's</div></div>');
                } else if (type == 'user') {
                    $('#preChatInfo').hide();
                    $('.layout .content .chat .chat-body .messages').append('<div data-message-id="' + messageId + '" class="message-item outgoing-message"><div class="message-content">' + message + '</div><div class="message-action">' + time + ' ' + (type ? '<i class="ti-check"></i>' : '') + '</div></div>');
                } else if (type == 'greeting') {
                    $('.layout .content .chat .chat-body .messages').append('<div data-message-id="' + messageId + '" class="message-item incoming-message"><div class="message-content">' + message + '</div></div>');
                } else if (type == 'profanity') {
                    $('#preChatInfo').hide();
                    $('.layout .content .chat .chat-body .messages').append('<div data-message-id="' + messageId + '" class="message-item incoming-message"><div class="message-content" style="background-color:orange;"><i class="fa fa-exclamation-triangle"></i> Deine Nachricht wurde vom System als unangemessen gekennzeichnet.</div></div>');
                } else if (type == 'context') {
                    $('#preChatInfo').hide();
                    $('#chatCharacterTitle').html(messageRaw);
                    $('#chatTitle').data('chat-title', messageRaw);
                    if (reloadChatsOnContextUpdate) {
                        loadChats(true);
                    }
                } else if (type == 'pending') {
                    $('#preChatInfo').hide();
                    $('.layout .content .chat .chat-body .messages').append('<div class="messagePendingIndicator">' + message + '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>');
                }

                chat_body.scrollTop(chat_body.get(0).scrollHeight, -1).niceScroll({
                    cursorcolor: 'rgba(66, 66, 66, 0.20)',
                    cursorwidth: "4px",
                    cursorborder: '0px'
                }).resize();
        }
        }
    }
};


$(document).on('submit', '.layout .content .chat .chat-footer form', function (e) {
    e.preventDefault();

    var input = $('#chatMessageInput');
    var message = input.val();

    message = $.trim(message);
    if (message.length > 2000) {
        showMessageToUser('Achtung', 'Die Eingabe im Textfeld darf maximal 2000 Zeichen betragen');
        return;
    }
    if (message) {
        input.val('');
        sendMessage($('#chatCharacterName').data('chat-id'), message);
    } else {
        input.focus();
    }
});

$(document).on('click', '.layout .content .sidebar-group .sidebar .chat-list-item', function () {
    //if (jQuery.browser.mobile) {
    $('.sidebar-group').removeClass('mobile-open');
    //}
});

function responseErrorHandler(xhr) {
    if (xhr.status == 401) {
        localStorage.clear();
        location.reload();
    }
    if (xhr.status == 403) {
        console.log(xhr.responseJSON.data);
        showBlockedModal(xhr.responseJSON.data);
    }
}

function loadChats(forceReload = false) {
    console.log('Loading Chats');
    $('#chatsContainer').html(getPreloaderHtml());
    if (!forceReload) {
        let cache_at = localStorage.getItem('chatsCache_at');
        if (typeof cache_at != 'undefined' && cache_at != null) {
            if (parseInt(cache_at) + 60 * 1000 * chatsCacheTimeMinutes >= Date.now()) {
                let cache = localStorage.getItem('chatsCache');
                if (typeof cache != 'undefined' && cache != null) {
                    renderChats(JSON.parse(cache));
                    return;
                }
            }
        }
    }
    $.ajax({
        type: "GET",
        url: apiUrl + 'chat/index',
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            localStorage.setItem('chatsCache', JSON.stringify(data));
            localStorage.setItem('chatsCache_at', Date.now());
            renderChats(data);
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function renderChats(data) {
    $('#chatsContainer').html('');
    var characterFilters = ['Alle'];
    $(data).each(function () {
        if (typeof this.ai_character != 'undefined' && this.ai_character != null) {
            if (!characterFilters.includes(this.ai_character.name)) {
                characterFilters.push(this.ai_character.name);
            }
            var messageTs = Date.parse(this.latest_message.created_at);
            var messageTime = new Date(messageTs);
            time = moment.utc(messageTime).local().locale('de').format('LLL');
            var messageSeen = (!this.latest_message.seen_by_user) ? '' : '';
            var activeChat = ($('#chatCharacterName').data('chat-id') == this.id) ? 'open-chat' : '';
            this.title = (this.title) ? this.title : 'Unbenanntes Gespräch';
            var html = `<li class="list-group-item chat-list-item ` + activeChat + `" id="chatItem` + this.id + `" data-chat-id="` + this.id + `" data-character-name="` + this.ai_character.name + `" data-last-message-id="` + this.latest_message.id + `">
                            <div>
                                <figure class="avatar ` + messageSeen + `">
                                    <img src="` + cdnUrl + 'images/' + this.ai_character.image + `" class="rounded-circle">
                                </figure>
                            </div>
                            <div class="users-list-body">
                                <h5>` + this.title + `</h5>
                                <p>` + this.ai_character.name + `</p>
                                <div class="users-list-action action-toggle">
                                </div>
                            </div>
                        </li>`;
            $('#chatsContainer').prepend(html);
            var that = this;
            $('#chatItem' + this.id).click(function () {
                $('.list-group-item').removeClass('open-chat');
                $(this).addClass('open-chat');
                $('.chat').show();
                $('.home').hide();
                if ($('#chatCharacterName').data('chat-id') != $(this).data('chat-id')) {
                    var chatId = $(this).data('chat-id');
                    loadChat(chatId);
                } else {
                    var lastMessage = $('#chatMessagesContainer .message-item').last();
                    console.log(lastMessage);
                    if (lastMessage.hasClass('outgoing-message')) {
                        pollForNewMessageOnce($(this).data('chat-id'));
                    }
                }
            });
        }
    });
    $('#chatsFilter').html('');
    $(characterFilters).each(function () {
        $('#chatsFilter').append('<span class="chatFilter badge badge-secondary" data-name="'+this+'">'+this+'</span>');
    });
    $('.chatFilter').off('click');
    $('.chatFilter').on('click', function() {
        var name = $(this).data('name');
        if (name == 'Alle') {
            $('.chat-list-item').show();
        } else {
            $('.chat-list-item').hide();
            $('.chat-list-item').each(function() {
               if ($(this).data('character-name') == name) {
                   $(this).show();
               }
            });
        }
         $('.chatFilter').removeClass('active');
         $(this).addClass('active');
    });
}

function loadChat(id) {
    console.log('Loading Chat');
    $('.sidebar-group').removeClass('mobile-open');
    let cache_at = localStorage.getItem(id + 'chatCache_at');
    if (typeof cache_at != 'undefined' && cache_at != null) {
        if (parseInt(cache_at) + 60 * 1000 * chatCacheTimeMinutes >= Date.now()) {
            let cache = localStorage.getItem(id + 'chatCache');
            if (typeof cache != 'undefined' && cache != null) {
                renderChat(JSON.parse(cache));
                return;
            }
        }
    }
    $.ajax({
        type: "GET",
        url: apiUrl + 'chat/' + id,
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            localStorage.setItem(data.id + 'chatCache', JSON.stringify(data));
            localStorage.setItem(data.id + 'chatCache_at', Date.now());
            renderChat(data);
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function renderChat(data) {
    $('#preChatInfo').show();
    $('#chatMessagesContainer').html('');
    $('#chatCharacterImage').attr('src', cdnUrl + 'images/' + data.ai_character.image);
    $('#chatCharacterName').data('chat-id', data.id);
    $('#chatCharacterName').html(data.ai_character.name);
    if (data.title) {
        $('#chatCharacterTitle').html(data.title);
    } else {
        $('#chatCharacterTitle').html('Unbenanntes Gespräch');
    }
    $('#chatTitle').data('chat-id', data.id);
    $('#chatTitle').data('chat-title', data.title);
    $('#chatCharacterDescription').html(data.ai_character.description);
    if (data.messages.length == 0) {
        SohoExamle.Message.add(data.ai_character.user_greeting, 'greeting', null, null, null, null, null, null);
    } else {
        $(data.messages).each(function () {
            SohoExamle.Message.add(this.content, this.type, this.created_at, this.tokens, this.time, this.id, this.favorite, this.hide_from_ai);
        });
    }
    if (data.messages.length > 0) {
        $('#chatCharacterName').data('last-message-id', data.messages[data.messages.length - 1].id);
    } else {
        $('#chatCharacterName').data('last-message-id', 0);
    }
    let character = data.ai_character;
    $('#chatCharacterImage').off('click');
    $('#chatCharacterImage').on('click', function () {
        showCharacterCard(character);
    });
    $('.chatTitleSettings').off('click');
    $('.chatTitleSettings').on('click', function () {
        showEditChatTitleModal();
    });
    setTimeout(function () {
        $('.modal').modal('hide');
    }, 500);
    pollForNewMessageOnce(data.id);
}

function sendMessage(id, message) {
    console.log('Sending Message');
    if (message.length >= 2000) {
        console.log('message-too-long');
    }
    $.ajax({
        type: "POST",
        url: apiUrl + 'chat/' + id,
        data: {content: message},
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            if (data.status == 'error') {
                if (data.message == 'insufficient-credits') {
                    $('#insufficientCreditsModal').modal('show');
                }
            } else {
                SohoExamle.Message.add(data.message.content, data.message.type, data.message.created_at, data.message.tokens, data.message.time, data.message.id);
                SohoExamle.Message.add($('#chatCharacterName').html() + ' denkt nach...', 'pending', data.message.created_at, data.message.tokens, data.message.time, data.message.id);
                localStorage.setItem(id + 'chatCache', null);
                localStorage.setItem(id + 'chatCache_at', null);
                pollForNewMessage(id, data.message.id, 10);
            }
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function showMessageToUser(title, message) {
    $('#userMessageModalTitle').html(title);
    $('#userMessageModalText').html(message);
    $('#userMessageModal').modal('show');
}

function createCharacter() {
    console.log('Creating Character');
    var form_data = new FormData();  
    
    var name = $('#createCharacterName').val().trim();
    if (name == '') {
        showMessageToUser('Problem', 'Du musst noch einen Namen für den Charakter angeben');
        return;
    }
    if (name.length > 20 || name.length < 3) {
        showMessageToUser('Problem', 'Der Name muss mindestens 3 Zeichen und höchstens 20 Zeichen beinhalten');
        return;
    }
    var validationPattern = /^[a-zA-Z0-9ÄäÖöÜüß .]+$/;
    if (!validationPattern.test(name)) {
        showMessageToUser('Problem', 'Der Name darf nur Buchstaben und Ziffern enthalten');
        return;
    }
    form_data.append('name',name);
    
    var description = $('#createCharacterDescription').val().trim();
    if (description == '') {
        showMessageToUser('Problem', 'Du musst noch eine Beschreibung der Persönlichkeit für den Charakter angeben');
        return;
    }
    if (description.length > 2000 || description.length < 50) {
        showMessageToUser('Problem', 'Die Beschreibung der Persönlichkeit muss mindestens 50 Zeichen und höchstens 2000 Zeichen beinhalten');
        return;
    }
    form_data.append('description',description);
    
    
    var short_description = $('#createCharacterShortDescription').val().trim();
    if (short_description == '') {
        showMessageToUser('Problem', 'Du musst noch eine Kurzbeschreibung für den Charakter angeben');
        return;
    }
    if (short_description.length > 60 || short_description.length < 5) {
        showMessageToUser('Problem', 'Die Kurzbeschreibung muss mindestens 5 Zeichen und höchstens 60 Zeichen beinhalten');
        return;
    }
    form_data.append('short_description',short_description);
    
    var user_greeting = $('#createCharacterGreeting').val().trim();
    if (user_greeting == '') {
        showMessageToUser('Problem', 'Du musst noch eine Begrüßung für den Charakter angeben');
        return;
    }
    if (user_greeting.length > 60 || user_greeting.length < 5) {
        showMessageToUser('Problem', 'Die Begrüßung muss mindestens 5 Zeichen und höchstens 60 Zeichen beinhalten');
        return;
    }
    form_data.append('user_greeting',user_greeting);
    
    
    var temperature = $('#createCharacterTemperature').val();
    console.log(temperature);
    if (isNaN(temperature) || temperature > 1 || temperature < 0) {
        showMessageToUser('Problem', 'Ungültiger Wert für Kreativität');
        return;
    }
    form_data.append('temperature',temperature);
    
    
    var brain = $('#createCharacterBrain').val();
    if (isNaN(brain) || brain > 12 || brain < 1) {
        showMessageToUser('Problem', 'Ungültiger Wert für Gedächtnis');
        return;
    }
    form_data.append('brain',brain);
    
    
    var presence_penalty = $('#createCharacterPenalty').val();
    if (isNaN(presence_penalty) || presence_penalty > 1.5 || presence_penalty < -1.5) {
        showMessageToUser('Problem', 'Ungültiger Wert für Ausdrucksstärke');
        return;
    }
    form_data.append('presence_penalty',presence_penalty);
    
    var frequency_penalty = $('#createCharacterFrequency').val();
    if (isNaN(frequency_penalty) || frequency_penalty > 1.2 || frequency_penalty < -0.2) {
        showMessageToUser('Problem', 'Ungültiger Wert für Eloquenz');
        return;
    }
    form_data.append('frequency_penalty',frequency_penalty);
    
    
    var max_tokens = $('#createCharacterMaxCredits').val();
    if (isNaN(max_tokens) || max_tokens > 2000 || max_tokens < 10) {
        showMessageToUser('Problem', 'Ungültiger Wert für Anwortlänge');
        return;
    }
    form_data.append('max_tokens',max_tokens);
    
    var image_data = $('#createCharacterImage').data('image');  
    if (image_data == '') {
        showMessageToUser('Problem', 'Kein Bild für den Charakter festgelegt');
        return;
    }
    form_data.append('image', image_data);
    
    $.ajax({
        type: "POST",
        url: apiUrl + 'character/create',
        data: form_data,
        processData: false,
        contentType: false,
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            if (data.status == 'error') {
                if (data.message == 'insufficient-credits') {
                    $('#insufficientCoinsModal').modal('show');
                }
            } else {
                $('#characterCreatedModal').modal('show');
                $('#createCharacterModal').modal('hide');
                clearCharacterCreator();
                $('#characterCreationState').html('<div class="listPreloader"><div class="preloader"><div></div><div></div></div></div>');
                pollCharacterCreationState(data.character.id);
            }
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function updateCharacter(character) {
    console.log('Updating Character');
    var form_data = new FormData();  
    
    var name = $('#createCharacterName').val().trim();
    if (name == '') {
        showMessageToUser('Problem', 'Du musst noch einen Namen für den Charakter angeben');
        return;
    }
    if (name.length > 20 || name.length < 3) {
        showMessageToUser('Problem', 'Der Name muss mindestens 3 Zeichen und höchstens 20 Zeichen beinhalten');
        return;
    }
    var validationPattern = /^[a-zA-Z0-9ÄäÖöÜüß .]+$/;
    if (!validationPattern.test(name)) {
        showMessageToUser('Problem', 'Der Name darf nur Buchstaben und Ziffern enthalten');
        return;
    }
    form_data.append('name',name);
    
    var description = $('#createCharacterDescription').val().trim();
    if (description == '') {
        showMessageToUser('Problem', 'Du musst noch eine Beschreibung der Persönlichkeit für den Charakter angeben');
        return;
    }
    if (description.length > 2000 || description.length < 50) {
        showMessageToUser('Problem', 'Die Beschreibung der Persönlichkeit muss mindestens 50 Zeichen und höchstens 2000 Zeichen beinhalten');
        return;
    }
    form_data.append('description',description);
    
    
    var short_description = $('#createCharacterShortDescription').val().trim();
    if (short_description == '') {
        showMessageToUser('Problem', 'Du musst noch eine Kurzbeschreibung für den Charakter angeben');
        return;
    }
    if (short_description.length > 60 || short_description.length < 5) {
        showMessageToUser('Problem', 'Die Kurzbeschreibung muss mindestens 5 Zeichen und höchstens 60 Zeichen beinhalten');
        return;
    }
    form_data.append('short_description',short_description);
    
    var user_greeting = $('#createCharacterGreeting').val().trim();
    if (user_greeting == '') {
        showMessageToUser('Problem', 'Du musst noch eine Begrüßung für den Charakter angeben');
        return;
    }
    if (user_greeting.length > 60 || user_greeting.length < 5) {
        showMessageToUser('Problem', 'Die Begrüßung muss mindestens 5 Zeichen und höchstens 60 Zeichen beinhalten');
        return;
    }
    form_data.append('user_greeting',user_greeting);
    
    
    var temperature = $('#createCharacterTemperature').val();
    console.log(temperature);
    if (isNaN(temperature) || temperature > 1 || temperature < 0) {
        showMessageToUser('Problem', 'Ungültiger Wert für Kreativität');
        return;
    }
    form_data.append('temperature',temperature);
    
    
    var brain = $('#createCharacterBrain').val();
    if (isNaN(brain) || brain > 12 || brain < 1) {
        showMessageToUser('Problem', 'Ungültiger Wert für Gedächtnis');
        return;
    }
    form_data.append('brain',brain);
    
    
    var presence_penalty = $('#createCharacterPenalty').val();
    if (isNaN(presence_penalty) || presence_penalty > 1.5 || presence_penalty < -1.5) {
        showMessageToUser('Problem', 'Ungültiger Wert für Ausdrucksstärke');
        return;
    }
    form_data.append('presence_penalty',presence_penalty);
    
    var frequency_penalty = $('#createCharacterFrequency').val();
    if (isNaN(frequency_penalty) || frequency_penalty > 1.2 || frequency_penalty < -0.2) {
        showMessageToUser('Problem', 'Ungültiger Wert für Eloquenz');
        return;
    }
    form_data.append('frequency_penalty',frequency_penalty);
    
    
    var max_tokens = $('#createCharacterMaxCredits').val();
    if (isNaN(max_tokens) || max_tokens > 2000 || max_tokens < 10) {
        showMessageToUser('Problem', 'Ungültiger Wert für Anwortlänge');
        return;
    }
    form_data.append('max_tokens',max_tokens);
    
    var image_data = $('#createCharacterImage').data('image');  
    if (image_data != '') {
        form_data.append('image', image_data);
    }
    
    $.ajax({
        type: "POST",
        url: apiUrl + 'character/update/' + character.id,
        data: form_data,
        processData: false,
        contentType: false,
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            if (data.status == 'error') {
                if (data.message == 'edit-character-blocked') {
                    showMessageToUser('Achtung', 'Es stehen keine weiteren Bearbeitungen für diesen Charakter zur Verfügung!');
                }
            } else {
                $('#characterUpdatedModal').modal('show');
                $('#createCharacterModal').modal('hide');
                $('#createCharacterName').val('');
                $('#createCharacterDescription').val('');
                $('#createCharacterShortDescription').val('');
                $('#createCharacterGreeting').val('');
                $('#createCharacterTemperature').val('');
                $('#createCharacterImage').attr('src','images/placeholder.png');
                $('#createCharacterImage').data('image', ''); 
                $('#characterUpdateState').html('<div class="listPreloader"><div class="preloader"><div></div><div></div></div></div>');
                pollCharacterUpdateState(character.id);
            }
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function removeFavorite(id) {
    showConfirmationToUser('Favoriten entfernen', 'Die Nachricht wird aus den Favoriten entfernt. Falls der Chat gelöscht wurde kann die Nachricht nicht wiederhergestellt werden.', function() {
        setFavorite(id, 0);
    });
}

function setFavorite(id, state) {
    console.log('Setting Favorite');
    $.ajax({
        type: "POST",
        url: apiUrl + 'chatMessage/' + id + '/favorite',
        data: {favorite: state},
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            $('#favoriteCardModal').modal('hide');
            localStorage.setItem('favoriteCache', null);
            localStorage.setItem('favoriteCache_at', null);
            loadFavorites();
            console.log(data);
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function setTitle(chatId, title) {
    console.log('Setting Chat Title');
    $.ajax({
        type: "POST",
        url: apiUrl + 'chat/' + chatId + '/title',
        data: {title: title},
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            $('#chatTitleEditModal').modal('hide');
            $('#chatCharacterTitle').html(title);
            $('#chatTitle').data('chat-title', title);
            localStorage.setItem('chatsCache', null);
            localStorage.setItem('chatsCache_at', null);
            loadChats();
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function deleteMessage(id) {
    console.log('Delete Message');
    $.ajax({
        type: "POST",
        url: apiUrl + 'chatMessage/' + id + '/delete',
        data: {delete: true},
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            var chatId = $('#chatCharacterName').data('chat-id');
            localStorage.setItem(chatId + 'chatCache', null);
            localStorage.setItem(chatId + 'chatCache_at', null);
            loadChat(chatId);
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function hideFromAiMessage(id, state) {
    console.log('Hide From AI Message');
    $.ajax({
        type: "POST",
        url: apiUrl + 'chatMessage/' + id + '/hideAi',
        data: {hideAi: state},
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            var chatId = $('#chatCharacterName').data('chat-id');
            localStorage.setItem(chatId + 'chatCache', null);
            localStorage.setItem(chatId + 'chatCache_at', null);
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function changeUsername() {
    console.log('Hide From AI Message');
    var newName = $('#userNameInput').val();
    if (newName.length > 20 || newName.length < 3) {
        showMessageToUser('Problem', 'Der Name muss mindestens 3 Zeichen und höchstens 20 Zeichen beinhalten');
        return;
    }
    $.ajax({
        type: "POST",
        url: apiUrl + 'user/changeName',
        data: {username: newName},
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            localStorage.setItem('userCache', null);
            localStorage.setItem('userCache_at', null);
            loadUser();
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function pollForNewMessageOnce(chatId) {
    var lastMessageId = $('#chatMessagesContainer .message-item').last().data('message-id');
    $.ajax({
        type: "GET",
        url: apiUrl + 'chat/' + chatId + '/poll/' + lastMessageId,
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            console.log($('#chatTitle').data('chat-id') + ' - ' + chatId);
            if ($('#chatTitle').data('chat-id') == chatId) {
                if (data.length > 0) {
                    $(data).each(function () {
                        SohoExamle.Message.add(this.content, this.type, this.created_at, this.tokens, this.time, this.id);
                    });
                }
            }
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function pollCharacterCreationState(characterId, maxTrys = 10, retryAfter = 5000, currentTry = 1) {
    console.log('Starting to poll for characterState');
    setTimeout(function () {
        console.log('Polling for state');
        $.ajax({
            type: "GET",
            url: apiUrl + 'character/status/' + characterId,
            headers: {
                Authorization: 'Bearer ' + apiToken
            },
            success: function (data) {
                if (data.status == 'pending') {
                    // Waiting
                    if (currentTry < maxTrys) {
                        pollCharacterCreationState(characterId, maxTrys, retryAfter, currentTry + 1);
                    } else {
                        $('#characterCreatedModal').modal('show');
                        $('#characterCreationState').html('<div class="alert alert-warning" role="alert">Die Überprüfung deines erstellen Charakters dauert länger als gewöhnlich. Du kannst dieses Fenster schliessen und er wird in der Übersicht auftauchen sobald er bereit ist.</div>');
                    }
                } else if (data.status == 'blocked') {
                    // Blocked
                    $('#characterCreatedModal').modal('show');
                    $('#characterCreationState').html('<div class="alert alert-danger" role="alert">Charakter wurde vom System abgelehnt. Es tut uns leid, aber wir können keinen Charakter erstellen, der gegen unsere Nutzungsbedingungen verstößt. Wir bitten um Dein Verständnis.</div>');
                } else if (data.status == 'success') {
                    // Approved
                    $('#characterCreatedModal').modal('show');
                    $('#characterCreationState').html('<div class="alert alert-success" role="alert">Dein Charakter '+data.character.name+' wurde freigeschaltet.</div>');
                    localStorage.setItem('characterCache', null);
                    localStorage.setItem('characterCache_at', null);
                    loadCharacters();
                    clearCharacterCreator();
                }
            },
            error: function (xhr) {
                pollCharacterCreationState(characterId, maxTrys, retryAfter, currentTry + 1);
                responseErrorHandler(xhr);
            }
        });
    }, 10000);
}

function pollCharacterUpdateState(characterId, maxTrys = 10, retryAfter = 5000, currentTry = 1) {
    console.log('Starting to poll for characterState');
    setTimeout(function () {
        console.log('Polling for state');
        $.ajax({
            type: "GET",
            url: apiUrl + 'character/status/' + characterId,
            headers: {
                Authorization: 'Bearer ' + apiToken
            },
            success: function (data) {
                if (data.status == 'pending') {
                    // Waiting
                    if (currentTry < maxTrys) {
                        pollCharacterUpdateState(characterId, maxTrys, retryAfter, currentTry + 1);
                    } else {
                        $('#characterUpdatedModal').modal('show');
                        $('#characterUpdateState').html('<div class="alert alert-warning" role="alert">Die Überprüfung deine Charakters dauert länger als gewöhnlich. Du kannst dieses Fenster schliessen und er wird in der Übersicht auftauchen sobald er bereit ist.</div>');
                    }
                } else if (data.status == 'blocked') {
                    // Blocked
                    $('#characterUpdatedModal').modal('show');
                    $('#characterUpdateState').html('<div class="alert alert-danger" role="alert">Charakter wurde vom System abgelehnt. Es tut uns leid, aber wir können keinen Charakter erstellen, der gegen unsere Richtlinien für moralisches und ethisches Verhalten verstößt. Wir bitten um Ihr Verständnis.</div>');
                } else if (data.status == 'success') {
                    // Approved
                    $('#characterUpdatedModal').modal('show');
                    $('#characterUpdateState').html('<div class="alert alert-success" role="alert">Dein Charakter '+data.character.name+' wurde freigeschaltet.</div>');
                    localStorage.setItem('characterCache', null);
                    localStorage.setItem('characterCache_at', null);
                    loadCharacters();
                    clearCharacterCreator();
                }
            },
            error: function (xhr) {
                pollCharacterUpdateState(characterId, maxTrys, retryAfter, currentTry + 1);
                responseErrorHandler(xhr);
            }
        });
    }, 10000);
}

function pollForNewMessage(chatId, lastMessageId, maxTrys = 10, retryAfter = 5000, currentTry = 1) {
    console.log('Starting to poll for new messages');
    setTimeout(function () {
        console.log('Polling for new messages');
        $.ajax({
            type: "GET",
            url: apiUrl + 'chat/' + chatId + '?since_id=' + lastMessageId,
            headers: {
                Authorization: 'Bearer ' + apiToken
            },
            success: function (data) {
                if ($('#chatTitle').data('chat-id') == chatId) {
                    if (data.messages.length > 0) {
                        $(data.messages).each(function () {
                            if (this.type != 'user') {
                                SohoExamle.Message.add(this.content, this.type, this.created_at, this.tokens, this.time, this.id, this.favorite, this.hide_from_ai, true);
                            }
                        });
                    } else {
                        if (currentTry < maxTrys) {
                            pollForNewMessage(chatId, lastMessageId, maxTrys, retryAfter, currentTry + 1);
                        } else {
                            showMessageToUser('Beschäftigt', 'Es sieht aus als ob der Charakter mit dem du schreiben möchtest gerade länger als gewöhnlich braucht um zu antworten. Deine Nachricht erscheint sobald er geantwortet hat.')
                        }
                    }
                }
            },
            error: function (xhr) {
                responseErrorHandler(xhr);
            }
        });
    }, retryAfter);
}

function createChat(id) {
    console.log('Creating Chat');
    $.ajax({
        type: "POST",
        url: apiUrl + 'chat/create',
        data: {character_id: id},
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            $('.chat').show();
            $('.home').hide();
            loadChat(data.id);
            localStorage.setItem('chatsCache', null);
            localStorage.setItem('chatsCache_at', null);
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function deleteChat(id) {
    console.log('Delete Chat Request');
    showConfirmationToUser('Löschen', 'Soll der Chat wirklich gelöscht werden? Alle Nachrichten ausser deinen gespeicherten Favoriten werden gelöscht.', function() {
        $.ajax({
            type: "GET",
            url: apiUrl + 'chat/' + id + '/delete',
            headers: {
                Authorization: 'Bearer ' + apiToken
            },
            success: function (data) {
                $('#chatTitleEditModal').modal('hide');
                $('.chat').hide();
                $('.home').show();
                localStorage.setItem('chatsCache', null);
                localStorage.setItem('chatsCache_at', null);
                loadChats();
            },
            error: function (xhr) {
                responseErrorHandler(xhr);
            }
        });
    });
}

function showConfirmationToUser(title, message, callback) {
    $('#userConfirmationModalTitle').html(title);
    $('#userConfirmationModalText').html(message);
    $('#userConfirmationModal').modal('show');
    $('#userConfirmationModalConfirmButton').off('click');
    $('#userConfirmationModalConfirmButton').one('click', function() {
        $('#userConfirmationModal').modal('hide');
        if (callback) {
            callback();
        }
    });
}

function getPreloaderHtml() {
    return '<li class="list-group-item character-list-item"><div class="listPreloader"><div class="preloader"><div></div><div></div></div></div></li>';
}

function loadCharacters() {
    console.log('Loading Characters');
    $('#charactersContainer').html(getPreloaderHtml());
    let cache_at = localStorage.getItem('characterCache_at');
    if (typeof cache_at != 'undefined' && cache_at != null) {
        if (parseInt(cache_at) + 60 * 1000 * characterCacheTimeMinutes >= Date.now()) {
            let cache = localStorage.getItem('characterCache');
            if (typeof cache != 'undefined' && cache != null) {
                renderCharacters(JSON.parse(cache));
                return;
            }
        }
    }
    $.ajax({
        type: "GET",
        url: apiUrl + 'character/index',
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            localStorage.setItem('characterCache', JSON.stringify(data));
            localStorage.setItem('characterCache_at', Date.now());
            renderCharacters(data);
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function renderCharacters(data) {
    $('#charactersContainer').html('');
    var types = ['Alle'];
    $(data).each(function () {
        if (!types.includes(this.type)) {
            types.push(this.type);
        }
        var html = `<li class="list-group-item character-list-item" id="charItem` + this.id + `" data-chat-id="` + this.id + `" data-type="` + this.type + `">
                        <div>
                            <figure class="avatar">
                                <img src="` + cdnUrl + `images/` + this.image + `" class="rounded-circle">
                            </figure>
                        </div>
                        <div class="users-list-body">
                            <h5>` + this.name + `</h5>
                            <p>` + this.short_description + `</p>
                            <h6><span class="badge badge-secondary">` + this.type + `</span></h6>
                        </div>
                    </li>`;
        $('#charactersContainer').prepend(html);
        var that = this;
        $('body').off('click', '#charItem' + this.id);
        $('body').on('click', '#charItem' + this.id, function () {
            showCharacterCard(that);
        });
    });
    $('#characterFilter').html('');
    $(types).each(function () {
        $('#characterFilter').append('<span class="characterFilter badge badge-secondary" data-type="'+this+'">'+this+'</span>');
    });
    $('.characterFilter').off('click');
    $('.characterFilter').on('click', function() {
        var type = $(this).data('type');
        if (type == 'Alle') {
            $('.character-list-item').show();
        } else {
            $('.character-list-item').hide();
            $('.character-list-item').each(function() {
               if ($(this).data('type') == type) {
                   $(this).show();
               }
            });
        }
         $('.characterFilter').removeClass('active');
         $(this).addClass('active');
    });
}

function showEditChatTitleModal() {
    $('#chatTitleInput').val($('#chatTitle').data('chat-title'));
    $('#saveChatTitle').data('chat-id', $('#chatTitle').data('chat-id'));
    $('#deleteChat').data('chat-id', $('#chatTitle').data('chat-id'));
    $('#chatTitleEditModal').modal('show');
}

function showCharacterCard(character) {
    $('#characterCardName').html(character.name);
    $('#characterCardButtonName').html(character.name);
    $('#characterCardImage').attr('src', cdnUrl + 'images/' + character.image);
    $('#characterCardDescription').html(character.description);
    $('.startChatFromCharacterCard').data('character-id', character.id);
    $('#characterCardEditButton').off('click');
    if (character.user_character) {
        if (character.editable) {
        $('#characterCardEdit').show();
        $('#characterCardEditButton').on('click', function() {
            editCharacter(character.id);
        });
        $('#characterCardEditButton').prop('disabled', false);
    } else {
        $('#characterCardEditButton').prop('disabled', true);
    }
    } else {
        $('#characterCardEdit').hide();
    }
    $('#characterCardModal').modal('show');
}

function clearCharacterCreator() {
    $('.characterCreatorIntro').show();
    $('.characterCreatorStep1').hide();
    $('.characterCreatorStep2').hide();
    $('.characterCreatorStep3').hide();
    $('.characterCreatorStep4').hide();
    $('.characterCreatorStep5').hide();
    $('#createCharacterName').val('');
    $('#createCharacterDescription').val('');
    $('#createCharacterShortDescription').val('');
    $('#createCharacterGreeting').val('');
    $('#createCharacterTemperature').val(0.8);
    $('#createCharacterPenalty').val(0);
    $('#createCharacterFrequency').val(0);
    $('#createCharacterBrain').val(4);
    $('#createCharacterMaxCredits').val(600);
    $('#createCharacterImage').attr('src','images/placeholder.png');
    $('#createCharacterImage').data('image', ''); 
    $('#createCharacterImagePlaceholder').attr('src','images/placeholder.png');
}

function editCharacter(id) {
    clearCharacterCreator();
    $.ajax({
        type: "GET",
        url: apiUrl + 'character/show/' + id,
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            if (data.status == 'success') {
                var character = data.character;
                var editsRemaining = 5 - character.edits;
                editsRemaining = (editsRemaining < 0) ? 0 : editsRemaining;
                $('#characterCardModal').modal('hide');
                $('#createCharacterModal').modal('show');
                $('#createCharacterModalTitle').html('Charakter bearbeiten');
                $('#createCharacterModalText').html('Du kannst diesen Charakter noch <b>' + editsRemaining + '</b> mal bearbeiten.');
                $('#createCharacterButton').html('Änderungen speichern <i class="fa fa-pencil" style="margin-left:10px;"></i>');
                $('#createCharacterSaveText').html('<b>Du kannst deine Änderungen am Charakter durch einen Klick auf den Button jetzt speichern.</b> <br /><br />Deine Änderungen werden anschließend überprüft. Du kannst diesen Charakter noch <b>' + editsRemaining + '</b> mal bearbeiten.');
                $('#createCharacterName').val(character.name);
                $('#createCharacterImagePlaceholder').attr('src', cdnUrl + 'images/' + character.image);
                $('#createCharacterGreeting').val(character.user_greeting);
                $('#createCharacterShortDescription').val(character.short_description);
                $('#createCharacterDescription').val(character.description);
                $('#createCharacterTemperature').val(character.temperature);
                $('#createCharacterTemperature').val(character.temperature);
                $('#createCharacterPenalty').val(character.presence_penalty);
                $('#createCharacterFrequency').val(character.frequency_penalty);
                $('#createCharacterBrain').val(character.brain);
                $('#createCharacterMaxCredits').val(character.max_tokens);
                $('#createCharacterButton').off('click');
                $('#createCharacterButton').on('click', function () {
                    updateCharacter(character);
                });
            }
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function showFavoriteCard(message) {
    $('#favoriteCardName').html(message.ai_character.name);
    $('#favoriteCardCredits').html(message.tokens);
    var messageTs = Date.parse(message.created_at);
    var messageTime = new Date(messageTs);
    var time = moment.utc(messageTime).local().locale('de').format('LLL');
    $('#favoriteCardDate').html(time);
    $('#favoriteCardMessage').html(message.response_to.content.replace(/\n/g, "<br />"));
    $('#favoriteCardContent').html(markdown(message.content).replace(/\n/g, "<br />"));
    $('#removeFavoriteMessage').data('message-id', message.id);
    $('#favoriteCardImage').attr('src', cdnUrl + 'images/' + message.ai_character.image);
    $('#favoriteCardModal').modal('show');
}

function showBlockedModal(time) {
    $('#blockedModal').modal('show');
    $('#blockTime').html(moment.utc(time).local().locale('de').format('LLL'));
    $("#blockedModal").on('hide.bs.modal', function () {
        return false
    });
}

function showBuyModal() {
    console.log('Loading Products');
    $('#creditsModal').modal('hide');
    $('#productsModal').modal('show');
    $('.modal').css('overflow-y', 'auto');
    $('#productsContainer').html(getPreloaderHtml());
    let cache_at = localStorage.getItem('productsCache_at');
    if (typeof cache_at != 'undefined' && cache_at != null) {
        if (parseInt(cache_at) + 60 * 1000 * productsCacheTimeMinutes >= Date.now()) {
            let cache = localStorage.getItem('productsCache');
            if (typeof cache != 'undefined' && cache != null) {
                renderProducts(JSON.parse(cache));
                return;
            }
        }
    }
    $.ajax({
        type: "GET",
        url: apiUrl + 'product/index',
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            localStorage.setItem('productsCache', JSON.stringify(data));
            localStorage.setItem('productsCache_at', Date.now());
            renderProducts(data);
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function renderProducts(data) {
    $('#productsContainer').html('');
    $(data).each(function () {
        $('#productsContainer').append(getProductHtml(this));
        let that = this;
        $('#buy' + this.sku).one('click', function () {
            showProductBuyModal(that);
        });
    });
}

function getProductHtml(data) {
    return '<li class="list-inline-item" data-sku="' + data.sku + '" data-price="' + data.price_eur + '"><div class="card productCard"><div class="card-header"><b>' + data.title + '</b></div><div class="card-body"><div class="row"><div class="col-sm-5"><h2 class="productPrice">€ ' + data.price_eur + '</h2><small>inkl. USt.</small><hr />Enthält: <br />' + data.credits + ' MindBit <br />' + data.coins + ' MindSpark <hr /></div><div class="col-sm-7">' + data.description + '<hr /><button type="button" class="btn btn-success" id="buy' + data.sku + '"><i class="fa fa-shopping-cart" style="margin-right:10px;"></i> Kaufen</button></div></div></card></li>';
}

function getProductDetailsHtml(data) {
    return '<li class="list-inline-item" data-sku="' + data.sku + '" data-price="' + data.price_eur + '"><div class="card productCard"><div class="card-header"><b>' + data.title + '</b></div><div class="card-body"><div class="row"><div class="col-sm-5"><h2 class="productPrice">€ ' + data.price_eur + '</h2><small>inkl. USt.</small><hr />Enthält: <br />' + data.credits + ' MindBit <br />' + data.coins + ' MindSpark <hr /></div><div class="col-sm-7">' + data.description + '</div></div></card></li>';
}

function showProductBuyModal(data) {
    $('#productsModal').modal('hide');
    $('#buyItemsModal').modal('show');
    $('.modal').css('overflow-y', 'auto');
    $('#buyProductSelectedPlaceholder').html(getProductDetailsHtml(data));
    $('#buyProductSelectedPlaceholder').data('sku', data.sku);
}

function loadFavorites() {
    console.log('Loading Favorites');
    $('#favoritesContainer').html(getPreloaderHtml());
    let cache_at = localStorage.getItem('favoriteCache_at');
    if (typeof cache_at != 'undefined' && cache_at != null) {
        if (parseInt(cache_at) + 60 * 1000 * favoritesCacheTimeMinutes >= Date.now()) {
            let cache = localStorage.getItem('favoriteCache');
            if (typeof cache != 'undefined' && cache != null) {
                renderFavorites(JSON.parse(cache));
                return;
            }
        }
    }
    $.ajax({
        type: "GET",
        url: apiUrl + 'user/favorites',
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            localStorage.setItem('favoriteCache', JSON.stringify(data));
            localStorage.setItem('favoriteCache_at', Date.now());
            renderFavorites(data);
        },
        error: function (xhr) {
            if (xhr.status == 0) {
                let cache = localStorage.getItem('favoriteCache');
                if (typeof cache != 'undefined' && cache != null) {
                    renderFavorites(JSON.parse(cache));
                    return;
                }
            }
            responseErrorHandler(xhr);
        }
    });
}

function renderFavorites(data) {
    $('#favoritesContainer').html('');
    $(data).each(function () {
        var html = `
                        <li class="list-group-item favorite-list-item" id="favItem` + this.id + `">
                            <div  class="users-list-body">
                                <h4>` + this.ai_character.name + `</h4>
                                <p>` + this.response_to.content + `</p>
                            </div>
                        </li>`;
        $('#favoritesContainer').prepend(html);
        var that = this;
        $('#favItem' + this.id).click(function () {
            showFavoriteCard(that);
        });
    });
}

function loadUser(live = false) {
    console.log('Loading User');
    if (!live) {
        let cache_at = localStorage.getItem('userCache_at');
        if (typeof cache_at != 'undefined' && cache_at != null) {
            if (parseInt(cache_at) + 60 * 1000 * userCacheTimeMinutes >= Date.now()) {
                let cache = localStorage.getItem('userCache');
                if (typeof cache != 'undefined' && cache != null) {
                    checkUserOnboardingStatus(JSON.parse(cache));
                    $('#homeUsername').html(JSON.parse(cache).name);
                    $('#userNameInput').val(JSON.parse(cache).name);
                    return;
                }
            }
        }
    }
    $.ajax({
        type: "GET",
        url: apiUrl + 'user/details',
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            if (data.status == 'success') {
                localStorage.setItem('userCache', JSON.stringify(data.user));
                localStorage.setItem('userCache_at', Date.now());
                $('#homeUsername').html(data.user.name);
                $('#userNameInput').val(data.user.name);
                checkUserOnboardingStatus(data.user);
            }
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function checkUserOnboardingStatus(user) {
    if (!user.onboarding) {
        $('#onboardingModal').modal('show');
        $("#onboardingModal").on('hide.bs.modal', function () {
            return false
        });
        $('#acceptDisclaimer').on('click', function() {
            var disclaimerAccepted = $('#acceptDisclaimer').prop('checked');
            if (!disclaimerAccepted) {
                $('#onboardingDisclaimerButton').prop('disabled', true);
            } else {
                $('#onboardingDisclaimerButton').prop('disabled', false);
            }
        });
        $('#acceptTos').on('click', function() {
            var tosAccepted = $('#acceptTos').prop('checked');
            if (!tosAccepted) {
                $('#onboardingAcceptTosButton').prop('disabled', true);
            } else {
                $('#onboardingAcceptTosButton').prop('disabled', false);
            }
        });
        $('#acceptPrivacy').on('click', function() {
            var privacyAccepted = $('#acceptPrivacy').prop('checked');
            if (!privacyAccepted) {
                $('#onboardingFinishButton').prop('disabled', true);
            } else {
                $('#onboardingFinishButton').prop('disabled', false);
            }
        });
        $('#onboardingFinishButton').on('click', function() {
            console.log('click');
            if (checkOnboardingState()) {
                var acceptContact = $('#acceptContact').prop('checked');
                var acceptUsageData = $('#acceptUsageData').prop('checked');
                $.ajax({
                    type: "POST",
                    url: apiUrl + 'user/onboarding',
                    data: {
                        user_confirmed: true,
                        usage_data_opt_in: acceptUsageData,
                        contact_opt_in: acceptContact
                    },
                    headers: {
                        Authorization: 'Bearer ' + apiToken
                    },
                    success: function (data) {
                        console.log(data);
                        if (data.status == 'success') {
                            localStorage.setItem('userCache', null);
                            localStorage.setItem('userCache_at', null);
                            $('#onboardingModal').modal('hide');
                            window.location.reload();
                        } else {
                            showMessageToUser('Oh nein', 'Anscheinend ist etwas schief gelaufen, bitte versuche es noch einmal!')
                        }
                    },
                    error: function (xhr) {
                        responseErrorHandler(xhr);
                    }
                });
            };
        });
    }
}

function checkOnboardingState() {
    var disclaimerAccepted = $('#acceptDisclaimer').prop('checked');
    var tosAccepted = $('#acceptTos').prop('checked');
    var privacyAccepted = $('#acceptPrivacy').prop('checked');
    if (disclaimerAccepted && tosAccepted && privacyAccepted) {
        return true;
    } else {
        return false;
    }
}

function loadCredits(live = false) {
    console.log('Loading Credits');
    if (!live) {
        let cache_at = localStorage.getItem('creditsCache_at');
        if (typeof cache_at != 'undefined' && cache_at != null) {
            if (parseInt(cache_at) + 60 * 1000 * creditsCacheTimeMinutes >= Date.now()) {
                let cache = localStorage.getItem('creditsCache');
                if (typeof cache != 'undefined' && cache != null) {
                    renderCredits(JSON.parse(cache));
                    return;
                }
            }
        }
    }
    $.ajax({
        type: "GET",
        url: apiUrl + 'user/credits',
        headers: {
            Authorization: 'Bearer ' + apiToken
        },
        success: function (data) {
            if (data.status == 'success') {
                localStorage.setItem('creditsCache', JSON.stringify(data.user));
                localStorage.setItem('creditsCache_at', Date.now());
                renderCredits(data.user);
            }
        },
        error: function (xhr) {
            responseErrorHandler(xhr);
        }
    });
}

function renderCredits(data) {
    $('#creditsAvailable').html(data.credits_available);
    $('#coinsAvailable').html(data.coins_available);
}